import React from 'react';
import { Link } from 'gatsby';

import * as styles from './Header.module.css';

const Header: React.FC = () => (
  <header className={styles.Header}>
    <Link to={`/`}>travel.write.eat</Link>
  </header>
);

export default Header;
