import React from 'react';

import * as styles from './Footer.module.css';

const Footer: React.FC = () => (
  <footer className={styles.Footer}>
    <div className={styles.Footer__social}>
      <a
        href="https://www.instagram.com/travel.write.eat/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Instagram
      </a>
    </div>
    <p>© {new Date().getFullYear()} travel.write.eat | all rights reserved</p>
  </footer>
);

export default Footer;
