import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import * as styles from './InstagramGallery.module.css';

interface IInstagramGallery {
  posts: any[];
}

const InstagramGallery: React.FC<IInstagramGallery> = ({ posts }) => {
  return (
    <div className={styles.InstagramGallery}>
      {posts.map(({ node }) => {
        if (node.localImage && node.permalink && node.caption) {
          const [title] = node.caption.split('#').map((c: string) => c.trim());

          return (
            <a
              className={styles.InstagramGallery__post}
              href={node.permalink}
              key={node.id}
              target="_blank"
            >
              <GatsbyImage
                image={node.localImage.childImageSharp.gatsbyImageData}
                key={node.id}
                alt={node.caption || 'Instagram Post'}
              />
              <span className={styles.InstagramGallery__post_caption}>
                {truncateString(title, 90)}
              </span>
            </a>
          );
        }
      })}
    </div>
  );
};

function truncateString(str: string, num: number) {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
}

export default InstagramGallery;
