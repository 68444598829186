import React from 'react';

import './reset.css';
import './globals.css';
import * as styles from './Layout.module.css';

import Header from '../Header';
import Footer from '../Footer';
import InstagramGallery from '../InstagramGallery';

type INativeDivElement = React.HTMLAttributes<HTMLDivElement>;

interface ILayout extends INativeDivElement {
  instagramPosts?: any[];
}

const Layout: React.FC<ILayout> = ({ children, instagramPosts, ...props }) => (
  <div className={styles.Layout} {...props}>
    <div className={styles.Layout__main}>
      <Header />
      <main className={styles.Main}>{children}</main>
    </div>
    {instagramPosts && <InstagramGallery posts={instagramPosts} />}
    <Footer />
  </div>
);

export default Layout;
